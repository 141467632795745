<template>
  <div>
    <a-card title="物料生产任务记录">
      <a-row gutter="16">
        <!-- 输入框 -->
        <a-col :span="4">
          <!-- 分配更多空间给输入框 -->
          <a-input-search
            v-model="searchForm.search"
            placeholder="单号,供应商编号/名称"
            style="width: 100%"
            allowClear
            @search="search"
          />
        </a-col>

        <!-- 选择框 -->
        <a-col :span="4">
          <!-- 调整span的值来分配空间 -->
          <a-select
            v-model="taskType"
            @change="changeTask"
            style="width: 100%"
            placeholder="选择任务状态"
            allowClear
          >
            <a-select-option value="Analysis">待审产</a-select-option>
            <!-- <a-select-option value="SamplePurchase">样品采购</a-select-option>
            <a-select-option value="Purchase">物料采购</a-select-option> -->
            <a-select-option value="InProduction">生产中</a-select-option>
            <a-select-option value="Complete">生产完成</a-select-option>
            <!-- <a-select-option value="Shipped">已发货</a-select-option> -->
            <a-select-option value="Cancel">作废</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="4">
          <a-button @click="downloadExcel">下载数据</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          :columns="columns"
          :data-source="items"
          size="small"
          :pagination="false"
          :loading="loading"
          :scroll="{ y: tableHeight }"
        >
          <div slot="date" slot-scope="value">
            {{ moment(value).format("YYYY-MM-DD") }}
          </div>

          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button type="link" @click="viewTask(item)">编辑</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>

      <div style="text-align: center; margin-top: 16px">
        <a-pagination
          v-model="searchForm.page"
          :total="totalRows"
          :pageSize="perPage"
          show-less-items
          @change="changePage"
        />
      </div>
    </a-card>
  </div>
</template>

<script>
import { purchaseOrderList } from "@/api/purchasing";
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  name: "WarehouseTask",
  data() {
    return {
      moment,
      taskType: undefined,
      items: [],
      perPage: 16,
      totalRows: 0,
      searchForm: { page: 1, page_size: 10, is_done: false },
      loading: false,
      statusList: [
        {
          id: 1,
          code: "Analysis",
          name: "待审生产",
        },
        //  {
        //   id: 2,
        //   code: 'SamplePurchase',
        //   name: '样品采购',
        // },
        //  {
        //   id: 3,
        //   code: 'Purchase',
        //   name: '物料采购',
        // },
        {
          id: 4,
          code: "InProduction",
          name: "生产中",
        },
        {
          id: 5,
          code: "Complete",
          name: "生产完成",
        },
        // {
        //   id: 7,
        //   code: 'Shipped',
        //   name: '已发货'
        // },
        {
          id: 6,
          code: "Cancel",
          name: "作废",
        },
      ],
      columns: [
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "下单用户",
          dataIndex: "creator_name",
          key: "creator_name",
          // width:'60px'
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          customRender: (text) => {
            const statusItem = this.statusList.find(
              (status) => status.code === text
            );
            return statusItem ? statusItem.name : "待审生产";
          },
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
        },
        {
          title: "数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableHeight: window.innerHeight,
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      this.searchForm["status"] = this.taskType;
      this.searchForm["supplier"] = 41;
      purchaseOrderList(this.searchForm)
        .then((resp) => {
          this.totalRows = resp.count;
          // this.items = resp.results;
          this.items = this.process_data(resp.results);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    process_data(data) {
      const updatedData = data.map((item) => {
        // 获取purchase_materials_items中的第一个元素
        const purchaseItem = item.purchase_materials_items[0] || {};

        // 合并数据
        const updatedItem = {
          ...item,
          materials: purchaseItem.materials,
          materials_name: purchaseItem.materials_name,
          materials_number: purchaseItem.materials_number,
          purchase_quantity: purchaseItem.purchase_quantity,
          total_amount: purchaseItem.total_amount,
        };

        // 删除purchase_materials_items
        delete updatedItem.purchase_materials_items;

        return updatedItem;
      });
      return updatedData;
    },

    viewTask(item) {
      // this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      this.$router.push({
        path: "/materials/production_record_detail",
        query: { 
          
          order_details: JSON.stringify({ 
            number: item.number, 
            purchase_quantity: item.purchase_quantity, 
            creator_name:item.creator_name,
            create_time: item.create_time ,
            status: item.status,
            id: item.id ,
            materials_id: item.materials,
            remark: item.remark,
          })
        },
      });
    },

    search() {
      this.searchForm.page = 1;
      // this.pagination.current = 1;
      this.list();
    },

    changeTask() {
      this.searchForm.page = 1;
      this.list();
    },
    changePage(value) {
      this.searchForm.page = value;
      this.list();
    },
    downloadExcel() {
      const ws = XLSX.utils.json_to_sheet(this.items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Data.xlsx");
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
